import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment as env } from '../../../environments/environment';
import { environment as prod } from "../../../environments/environment.prod";
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  API = "";

  constructor(private http: HttpClient) {
    if (env.production) {
      this.API = prod.API;
    } else {
      this.API = env.API;
    }
  }

  //API = env.API;
  // API_test = env.API_test;




  save(endpoint: any, object: object): Observable<any> {
    return this.http.post(`${this.API}${endpoint}`, object);
  }
  saveWithImage(endpoint: any, object: object, boatFile: any, signFile: any): Observable<any> {
    return this.http.post(`${this.API}${endpoint}`, object, {
      params: {
        "boatFile": boatFile,
        "signFile": signFile
      }
    });
  }

  update(endpoint: any, object: object): Observable<any> {
    return this.http.put(`${this.API}${endpoint}`, object);
  }

  findWithParam(endpoint: any, object: object): Observable<any> {
    return this.http.post(`${this.API}${endpoint}`, object, { responseType: 'blob' });
  }

  findAll(endpoint: any): Observable<any> {
    return this.http.get(`${this.API}${endpoint}`);
  }

  fetchPdfFile(endpoint: any): Observable<any> {
    return this.http.get(`${this.API}${endpoint}`, { responseType: 'arraybuffer' as 'json' });
  }

  findByUuid(endpoint: any, uuid: string): Observable<any> {
    return this.http.get(`${this.API}${endpoint}/uuid/${uuid}`);
  }

  findByParam(endpoint: any, param: any): Observable<any> {
    return this.http.get(`${this.API}${endpoint}/${param}`);
  }

  delete(endpoint: any, param: any): Observable<any> {
    return this.http.delete(`${this.API}${endpoint}/${param}`);
  }
}
