import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { AddressHeader } from '../model/address-header.model';
import { EncrDecrService } from './encr-decr.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class AddressService {

    constructor(private api: ApiService, private encrDecrService: EncrDecrService, private router: Router) { }

    keyCode: string = '!w@ntcOff33!!!!!';

    address: AddressHeader = new AddressHeader();
    municipalities = [];

    setUsername(decodedToken) {
        sessionStorage.setItem('USERNAME', decodedToken.sub);
    }
    getUserName() {
        return sessionStorage.getItem('USERNAME') ? sessionStorage.getItem('USERNAME') : null;
    }
    getAddresses(decodedToken: any) {

        this.setUsername(decodedToken);
        this.getFishingGears(decodedToken);
        this.getCaptureFishingMethodFromApi();
        this.getListFishingGearTypesFromApi();
        this.getListClassificationFishingGearTypesFromApi();
        this.getAllFishingGearsFromApi();
        this.getRegions();
        sessionStorage.setItem('USER_ID', decodedToken.user_profile_id);
        this.api.findByParam("account/user-profiles", "id/" + decodedToken.user_profile_id).subscribe((res: any) => {

            if (decodedToken.user_role === "ROLE_REGIONAL_SUPERVISOR") {
                this.getProvinces(res.regionCode);
                sessionStorage.setItem('USER_REGION', res.regionCode);
            }
            this.api.findByParam("global/regions/region-code", res.regionCode).subscribe((regionRes: any) => {
                this.api.findByParam("global/provinces/province-code", res.provinceCode).subscribe((provinceRes: any) => {
                    this.api.findByParam("global/city-municipalities/city-municipality-code", res.cityMunicipalityCode).subscribe((municipalityRes: any) => {
                        this.address.region = regionRes.responseData.data.regionName;
                        this.address.regionCode = res.regionCode;
                        this.address.province = provinceRes.responseData.data.provinceName;
                        this.address.provinceCode = res.provinceCode;
                        this.address.municipality = municipalityRes.responseData.data.cityMunicipalityName;
                        this.address.municipalityCode = res.cityMunicipalityCode;
                        this.address.username = decodedToken.sub;
                        sessionStorage.setItem('USER_ADDRESS', this.encrDecrService.set(this.keyCode, JSON.stringify(this.address)));
                        //console.log("decodedToken.user_role", decodedToken.user_role);
                        if (decodedToken.user_role == "ROLE_LGU_ENCODER" || decodedToken.user_role == "ROLE_LGU_SUPERVISOR") {
                            this.router.navigate(["../encoder", "encoder-home"]);
                        } else if (decodedToken.user_role == "ROLE_REGIONAL_SUPERVISOR") {
                            this.router.navigate(["../regional-supervisor", "regional-supervisor-home"]);
                        } else if (decodedToken.user_role == "ROLE_CENTRAL_ADMINISTRATOR") {
                            this.router.navigate(["../central-administrator", "central-administrator-home"]);
                        } else if (decodedToken.user_role == "ROLE_SUPER_ADMIN") {
                            this.router.navigate(["../super-admin", "super-admin-home"]);
                        } else {
                            alert("not valid!");
                        }
                    });
                });
            });
        });
    }

    getFishingGears(decodedToken) {
        this.api.findAll("global/category-fishing-gears").subscribe((categoryFishingGears: any) => {
            if (decodedToken.user_role == "ROLE_LGU_ENCODER") {
                categoryFishingGears.responseData.data.forEach((element, index) => {
                    element.image = "";
                    if (index == categoryFishingGears.responseData.data.length - 1) {
                        sessionStorage.setItem("CATEGORY_FISHING_GEARS", this.encrDecrService.set(this.keyCode, JSON.stringify(categoryFishingGears.responseData.data)));
                    }
                });
            } else {
                sessionStorage.setItem("CATEGORY_FISHING_GEARS", this.encrDecrService.set(this.keyCode, JSON.stringify(categoryFishingGears.responseData.data)));
            }

        });
    }
    //TRY
    getCaptureFishingMethodFromApi() {
        this.api.findAll("global/capture-fishing-methods").subscribe((captureFishingMethod: any) => {
            sessionStorage.setItem("CAPTURE_FISHING_METHOD", this.encrDecrService.set(this.keyCode, JSON.stringify(captureFishingMethod.responseData.data)));
        });
    }
    //END
    getListFishingGearTypesFromApi() {
        this.api.findAll("global/fishing-gear-types").subscribe((fishingGearTypes: any) => {
            sessionStorage.setItem("FISHING_GEAR_TYPES", this.encrDecrService.set(this.keyCode, JSON.stringify(fishingGearTypes.responseData.data)));
        });
    }

    getListClassificationFishingGearTypesFromApi() {
        this.api.findAll("global/classification-fishing-gears").subscribe((classificationFishingGears: any) => {
            //console.log("THIS====", classificationFishingGears);
            sessionStorage.setItem("CLASSIFICATION_FISHING_GEARS", this.encrDecrService.set(this.keyCode, JSON.stringify(classificationFishingGears.responseData.data)));
        });
    }

    getAllFishingGearsFromApi(): void {
        this.api.findAll("global/v-fishing-gears").subscribe((fishingGears: any) => {
            sessionStorage.setItem("LIST_OF_FISHING_GEARS", this.encrDecrService.set(this.keyCode, JSON.stringify(fishingGears)));
        })
    }

    getAllFishingGearsFromStorage() {
        return sessionStorage.getItem('LIST_OF_FISHING_GEARS') ? JSON.parse(this.encrDecrService.get(this.keyCode, sessionStorage.getItem('LIST_OF_FISHING_GEARS'))) : null;
    }
    //TRY
    getCaptureFishingMethodFromStorage() {
        return sessionStorage.getItem('CAPTURE_FISHING_METHOD') ? JSON.parse(this.encrDecrService.get(this.keyCode, sessionStorage.getItem('CAPTURE_FISHING_METHOD'))) : null;
    }
    //END
    getFishingGearTypesFromStorage() {
        return sessionStorage.getItem('FISHING_GEAR_TYPES') ? JSON.parse(this.encrDecrService.get(this.keyCode, sessionStorage.getItem('FISHING_GEAR_TYPES'))) : null;
    }

    getFishingGearsFromStorage() { // disregard this function
        return sessionStorage.getItem('FISHING_GEARS') ? JSON.parse(this.encrDecrService.get(this.keyCode, sessionStorage.getItem('FISHING_GEARS'))) : null;
    }

    getCategoryFishingGearsFromStorage() {
        return sessionStorage.getItem('CATEGORY_FISHING_GEARS') ? JSON.parse(this.encrDecrService.get(this.keyCode, sessionStorage.getItem('CATEGORY_FISHING_GEARS'))) : null;
    }

    getClassificationFishingGearsFromStorage() {
        return sessionStorage.getItem('CLASSIFICATION_FISHING_GEARS') ? JSON.parse(this.encrDecrService.get(this.keyCode, sessionStorage.getItem('CLASSIFICATION_FISHING_GEARS'))) : null;
    }

    getProvincesFromStorage() {
        return sessionStorage.getItem('PROVINCES') ? JSON.parse(this.encrDecrService.get(this.keyCode, sessionStorage.getItem('PROVINCES'))) : null;
    }

    getRegionsFromStorage() {
        return sessionStorage.getItem('REGIONS') ? JSON.parse(this.encrDecrService.get(this.keyCode, sessionStorage.getItem('REGIONS'))) : null;
    }

    getUserAddress() {
        return sessionStorage.getItem('USER_ADDRESS') ? JSON.parse(this.encrDecrService.get(this.keyCode, sessionStorage.getItem('USER_ADDRESS'))) : null;
    }

    getProvinces(regionCode) {
        this.api.findByParam("global/provinces/region-code", regionCode).subscribe((provinces: any) => {
            sessionStorage.setItem("PROVINCES", this.encrDecrService.set(this.keyCode, JSON.stringify(provinces.responseData.data)));
        });
    }

    getRegions() {
        this.api.findAll("global/regions").subscribe((regions: any) => {
            sessionStorage.setItem("REGIONS", this.encrDecrService.set(this.keyCode, JSON.stringify(regions.responseData.data)));
        });
    }
    getUserId() {
        return sessionStorage.getItem('USER_ID');
    }
}
