import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TokenStorage } from './token.storage';
import { AddressService } from '../address.service';

@Injectable({
    providedIn: 'root'
})
export class AuthHttpInterceptorService implements HttpInterceptor {

    constructor(private tokenStorage: TokenStorage, private addressService: AddressService) { }

    intercept(httpRequest: HttpRequest<any>, httpHandler: HttpHandler) {

        if (this.addressService.getUserName() && this.tokenStorage.getToken()) {
            httpRequest = httpRequest.clone({
                setHeaders: {
                    Authorization: `Bearer ${this.tokenStorage.getToken()}`
                }
            });
        }
        return httpHandler.handle(httpRequest);
    }
}
