// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,

  API: "https://frs_boatr_gateway.bfar.da.gov.ph/api/",

  // API: 'http://localhost:9091/api/',
  // API: "http://10.0.99.47:9091/api/",
  // API_test: "http://localhost:3000/",
  // GLOBAL_API: "http://localhost:9097/",
  // BOATR_API: "http://localhost:3333/boatr/",
  // FAKE_API: "http://localhost:3000/",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
