import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatSelectModule,
  MatOptionModule,
  MatFormFieldModule,
  MatInputModule,
  MatStepperModule,
  MatTabsModule,
  MatIconModule,
  MatButtonModule,
  MatTableModule,
  MatPaginatorModule,
  MatDialogModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatRadioModule,
  MatSortModule,
  MatDividerModule,
  MatTooltipModule,
  MatCheckboxModule,
  MatGridListModule,
  MatSnackBarModule,
  MatListModule,
  MatCardModule,
  MatExpansionModule
} from '@angular/material';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTableModule } from '@angular/cdk/table';
import { MatTableExporterModule } from 'mat-table-exporter';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { HighchartsChartModule } from 'highcharts-angular';

const MATERIAL_MODULES = [
  MatButtonModule,
  MatTableModule,
  MatPaginatorModule,
  MatSelectModule,
  MatOptionModule,
  MatFormFieldModule,
  MatInputModule,
  MatStepperModule,
  MatTabsModule,
  MatIconModule,
  MatDialogModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatRadioModule,
  MatSortModule,
  MatDividerModule,
  MatTooltipModule,
  CdkTableModule,
  MatTableExporterModule,
  MatCheckboxModule,
  MatGridListModule,
  MatSnackBarModule,
  MatListModule,
  MatCardModule,
  MatExpansionModule,
  CdkAccordionModule,
  HighchartsChartModule

];

const ANGULAR_FORMS = [
  FormsModule,
  ReactiveFormsModule
];


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ...MATERIAL_MODULES,
    ...ANGULAR_FORMS
  ],
  exports: [
    ...MATERIAL_MODULES,
    ...ANGULAR_FORMS
  ]
})
export class AngularMaterialModule { }
