import { Component } from "@angular/core";
import { NgwWowService } from "ngx-wow";
// import { ConfirmationDialogService } from 'src/app/core/services/confirmation-dialog.service';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "boatr-webapp";

  constructor(private wowService: NgwWowService) {
    this.wowService.init();
  }
  // constructor(private confirmationDialogService: ConfirmationDialogService) {}
  // public openConfirmationDialog() {
  //   this.confirmationDialogService.confirm('Please confirm..', 'Do you really want to update this ?')
  //   .then((confirmed) => console.log('User confirmed:', confirmed))
  //   .catch(() => console.log('User dismissed the dialog (e.g., by using ESC, clicking the cross icon, or clicking outside the dialog)'));
  // }
}
