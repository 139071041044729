import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "./shared/shared.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import {
  NbDialogModule,
  NbThemeModule,
  NbSidebarModule,
  NbMenuModule,
  NbWindowModule,
  NbDatepickerModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbCardModule,
  NbAlertModule,
  NbInputModule,
  NbButtonModule,
  NbIconModule,
  NbToastrModule,
} from "@nebular/theme";
import { AngularMaterialModule } from "./shared/utils/angular-material.module";
//utilities
import { LoginComponent } from "./login/login.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AuthHttpInterceptorService } from "./core/services/authentication/auth-http-interceptor.service";
import {
  MatAutocompleteModule,
  MatIconModule,
  MatInputModule,
  MatProgressSpinnerModule,
} from "@angular/material";
import { NgwWowModule } from "ngx-wow";

@NgModule({
  declarations: [AppComponent, LoginComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    HttpClientModule,
    NbDialogModule.forRoot(),
    NbThemeModule.forRoot(),
    NbSidebarModule.forRoot(),
    SharedModule.forRoot(),
    NbMenuModule.forRoot(),
    NbWindowModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    NbDatepickerModule.forRoot(),
    NbCalendarModule,
    NbInputModule,
    NbButtonModule,
    NbCardModule,
    MatInputModule,
    MatAutocompleteModule,
    NbAlertModule,
    NbIconModule,
    NgwWowModule,
    MatIconModule,
    MatProgressSpinnerModule,
    NbToastrModule.forRoot(),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
