import { Component, OnInit } from "@angular/core";
import { NbSidebarService } from "@nebular/theme";
import { LayoutService } from "../../core/services/layout.service";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
})
export class LayoutComponent implements OnInit {
  constructor(
    private _sidebarService: NbSidebarService,
    private _layoutService: LayoutService
  ) {}

  ngOnInit() {}

  toggleSidebar(): boolean {
    this._sidebarService.toggle(true, "menu-sidebar");
    this._layoutService.changeLayoutSize();

    return false;
  }
}
