import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "../core/services/api.service";
import { User } from "../core/model/user.model";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AddressService } from "../core/services/address.service";
import { TokenStorage } from "../core/services/authentication/token.storage";
import { NbGlobalLogicalPosition, NbToastrService } from "@nebular/theme";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  user: User = new User();
  helper = new JwtHelperService();
  decodedToken: any;
  hide: boolean = false;
  showPass: any;
  isLoading: boolean = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private userService: AddressService,
    private tokenStorage: TokenStorage,
    private _toastrService: NbToastrService
  ) {}

  ngOnInit() {}

  checkCredentials(user: any) {
    this.isLoading = true;
    this.api.save("auth/authentication/login", user).subscribe(
      (res: any) => {
        this.tokenStorage.saveToken(res.accessToken);
        this.decodedToken = this.helper.decodeToken(res.accessToken);
        this.userService.getAddresses(this.decodedToken);
        //console.log(this.decodedToken);
      },
      (error) => {
        this.isLoading = false;
        this.showErrorToast();
      }
    );
  }

  logoutUser() {
    if (this.tokenStorage.getToken() != null) {
      this.isLoading = true;

      this.api
        .save(
          "auth/authentication/log-out/" + this.tokenStorage.getToken(),
          null
        )
        .subscribe(
          (res) => console.log(""),
          (error) => (this.isLoading = false)
        );
      this.tokenStorage.signOut();
    }
  }

  showErrorToast() {
    this._toastrService.show(
      "Try entering your information again or resetting your password.",
      `Incorrect username or password`,
      {
        preventDuplicates: true,
        position: NbGlobalLogicalPosition.TOP_END,
        status: "control",
        duration: 10000,
      }
    );
  }
}
