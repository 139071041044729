import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LoginComponent } from "./login/login.component";


const routes: Routes = [
  {
    path: "",
    redirectTo: "login",
    pathMatch: "full"
  },
  {
    path: "login",
    component: LoginComponent
  },
  {
    path: "encoder",
    loadChildren: () => import('./modules/pages/boatr/encoder/encoder.module')
      .then(m => m.EncoderModule)
  },
  {
    path: "regional-supervisor",
    loadChildren: () => import('./modules/pages/boatr/regional-supervisor/regional-supervisor.module')
      .then(m => m.RegionalSupervisorModule)
  },
  {
    path: "central-administrator",
    loadChildren: () => import('./modules/pages/boatr/central-administrator/central-administrator.module')
      .then(m => m.CentralAdministratorModule)
  },
  {
    path: "super-admin",
    loadChildren: () => import('./modules/pages/boatr/super-admin/super-admin.module')
      .then(m => m.SuperAdminModule)
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
