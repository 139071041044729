import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { LayoutComponent } from "./layout/layout.component";
// import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import {
  NbLayoutModule,
  NbMenuModule,
  NbActionsModule,
  NbSidebarModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbUserModule,
  NbContextMenuModule,
  NbSearchModule,
  NbCardModule,
} from "@nebular/theme";
import { DEFAULT_THEME } from "./styles/theme.default";
import { NbEvaIconsModule } from "@nebular/eva-icons";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
// import { ConfirmationDialogService } from 'src/app/core/services/confirmation-dialog.service';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbCardModule,
];

const COMPONENTS = [FooterComponent, HeaderComponent, LayoutComponent];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [CommonModule, ...NB_MODULES],
  exports: [CommonModule, ...COMPONENTS, ...NB_MODULES],
  // providers: [ ConfirmationDialogService ],
  // entryComponents: [ ConfirmationDialogComponent ]
  // entryComponents: [ ConfirmationDialogComponent ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: "default",
          },
          [DEFAULT_THEME]
        ).providers,
      ],
    } as ModuleWithProviders;
  }
}
