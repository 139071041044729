import { Injectable } from '@angular/core';
import { EncrDecrService } from '../encr-decr.service';

@Injectable({
    providedIn: 'root'
})
export class TokenStorage {
    constructor(private encrDecrService: EncrDecrService) { }

    ON_LOGIN = 'false';
    keyCode: string = '!w@ntcOff33!!!!!';
    // b0@Tg3aR---sY$teM

    signOut() {
        sessionStorage.clear();
    }

    public saveToken(token: string) {
        sessionStorage.setItem('TOKEN_KEY', this.encrDecrService.set(this.keyCode, token));
    }

    public getToken() {
        return sessionStorage.getItem('TOKEN_KEY') ? this.encrDecrService.get(this.keyCode, sessionStorage.getItem('TOKEN_KEY')) : null;
    }

}
