import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { NbSidebarService, NbMenuService, NbThemeService, NbMediaBreakpointsService } from '@nebular/theme';
import { LayoutService } from '../../core/services/layout.service';
import { map, takeUntil } from 'rxjs/operators';
import { AddressService } from 'src/app/core/services/address.service';
import { ApiService } from 'src/app/core/services/api.service';
import { TokenStorage } from 'src/app/core/services/authentication/token.storage';
import { JwtHelperService } from "@auth0/angular-jwt";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly = false;

  currentTheme = 'default';
  useraddress: any;
  userLoggedIn: string = '';
  helper = new JwtHelperService();
  userAddress: string = '';

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private breakpointService: NbMediaBreakpointsService,
    private addressService: AddressService,
    private apiService: ApiService,
    private token: TokenStorage) {
  }

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.useraddress = this.addressService.getUserAddress();

    if (!this.addressService.getUserId() || this.addressService.getUserId() !== "") {
      this.apiService
        .findByParam("account/user-profiles", "id/" + this.addressService.getUserId())
        .subscribe((user: any) => {
          // console.log(user);
          // console.log(this.helper.decodeToken(this.token.getToken()));

          switch (this.helper.decodeToken(this.token.getToken()).user_role) {
            case "ROLE_LGU_ENCODER":
              this.userAddress = user.cityMunicipalityName + ', ' + user.provinceName;
              break;
            case "ROLE_LGU_SUPERVISOR":
              this.userAddress = user.cityMunicipalityName + ', ' + user.provinceName;
              break;
            case "ROLE_REGIONAL_SUPERVISOR":
              this.userAddress = user.regionName.substr(0, 1).toUpperCase() + user.regionName.substr(1).toLowerCase();
              break;
            case "ROLE_CENTRAL_ADMINISTRATOR":
              this.userAddress = "Central Office";
              break;
            default:
              break;
          }
          // this.userLoggedIn = user.firstName + user.lastName
          this.userLoggedIn = (user.firstName !== null ? (user.firstName.substr(0, 1).toUpperCase() + user.firstName.substr(1).toLowerCase()) : "")
            + " " + (user.lastName !== null ? (user.lastName.substr(0, 1).toUpperCase() + user.lastName.substr(1).toLowerCase()) : "");
        });
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }

}
