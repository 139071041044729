import * as CryptoJs from 'crypto-js';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class EncrDecrService {
    constructor() { }

    set(keys, value) {
        var key = CryptoJs.enc.Utf8.parse(keys);
        var iv = CryptoJs.enc.Utf8.parse(keys);
        var encrypted = CryptoJs.AES.encrypt(CryptoJs.enc.Utf8.parse(value.toString()), key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJs.mode.CBC,
            padding: CryptoJs.pad.Pkcs7
        });
        return encrypted.toString();
    }

    get(keys, value) {
        var key = CryptoJs.enc.Utf8.parse(keys);
        var iv = CryptoJs.enc.Utf8.parse(keys);
        var decrypted = CryptoJs.AES.decrypt(value, key, {
            keySize: 128 / 8,
            iv: iv,
            mode: CryptoJs.mode.CBC,
            padding: CryptoJs.pad.Pkcs7
        });
        return decrypted.toString(CryptoJs.enc.Utf8);
    }
}
